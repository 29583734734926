import { render, staticRenderFns } from "./fitment.vue?vue&type=template&id=5226ad29&scoped=true&"
import script from "./fitment.vue?vue&type=script&lang=js&"
export * from "./fitment.vue?vue&type=script&lang=js&"
import style0 from "./fitment.vue?vue&type=style&index=0&id=5226ad29&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5226ad29",
  null
  
)

export default component.exports